import {
  Text,
  Container,
  Title,
  Card,
  Grid,
  Tabs,
  Textarea,
  Group,
  Image,
  Paper,
  Code,
} from "@mantine/core";
import {
  IconExternalLink,
  IconCopy,
} from "@tabler/icons-react";
import React, { useContext, useEffect, useState } from "react";
import { useClipboard } from '@mantine/hooks';
import './integration.css';
import { showNotification } from '@mantine/notifications';
import { useTitle } from "../hooks/useTitle";
import { ConfigContext } from "../../Context/configContext";

const integrationData = [
    {
       title: "WordPress",
       imgUrl: "https://s.w.org/style/images/about/WordPress-logotype-wmark.png",
       link: "https://wordpress.org/plugins/cookiex-consent-management-platform/"
    },
    {
       title: "Google Tag Manager",
       imgUrl: "https://verseoads.com/wp-content/uploads/2022/04/google-tag-manager.png",
       link: "#"
    },
    /*
    {
       title: "Google Consent Mode",
       imgUrl: "https://images.prismic.io/secure-privacy/51540b1f-8b76-4e52-bb3e-ea6065f7a1a0_Shield-Google.png",
       link: "#"
    },
    {
       title: "Google Privacy Control",
       imgUrl: "https://static.vecteezy.com/system/resources/previews/002/152/302/original/user-privacy-control-icon-on-white-vector.jpg",
       link: "#"
    },*/
];

function IntegrationPage(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const clipboard = useClipboard({ timeout: 500 });
  const [domainId, setDomainId] = useState<any | null>(props?.currentConsentData?.domainId || localStorage.getItem('activeDomainId'));
  const [domainName, setDomainName] = useState<any | null>(props?.currentConsentData?.domain || localStorage.getItem('activeDomain'));

  useEffect(() => {
    if (props.currentConsentData) {
      setDomainId(props.currentConsentData.domainId);
      setDomainName(props.currentConsentData.domain);
    }
  }, [props.currentConsentData]);

  useTitle("Integration");

  const embScript = `
    <script id="cookiex" data-ckid=${domainId} src="${config?.bannerUrl}/cookiex.min.js" type="text/javascript"></script>
    <script type="text/javascript">
      document.addEventListener("DOMContentLoaded", function() {
            const theme = {
                domainId: '${domainId}',
                domainName: '${domainName}'
            };
            const cookiex = new Cookiex();
            cookiex.init(theme);
      });
    </script>
  `;

  return (
    <React.Fragment>
      <>
        <Container fluid>
          <Title mt="lg" mb="lg" order={2}>Integration</Title>
          <Card withBorder shadow="sm" radius="md">
            <Card.Section withBorder inheritPadding mt="sm" pb="md">
                <Group>
                  <Title order={3}>Implement CMP banner</Title>
                </Group>
                <Text size="xs" mt="sm">Implement the Cookies banner manually by adding the script tag below or follow of our installation guides</Text>
            </Card.Section>
            <Card.Section withBorder inheritPadding mt="sm" pb="md">
                <a href="https://wordpress.org/plugins/cookiex-consent-management-platform/" target="_blank" rel="noreferrer noopener" style={{color: 'blue', fontSize: '12px'}}>Wordpress <IconExternalLink size="12" stroke={1.5}  /> </a> &nbsp;&nbsp;
                <a href="#" target="_blank" rel="noreferrer noopener" style={{color: 'blue', fontSize: '12px'}}>Google Consent Mode <IconExternalLink stroke={1.5}  size="12" /></a> &nbsp;&nbsp;
                <a href="#" target="_blank" rel="noreferrer noopener" style={{color: 'blue', fontSize: '12px'}}>Google Tag Manager <IconExternalLink  stroke={1.5}   size="12" /></a> &nbsp;&nbsp;
                <a href="#" target="_blank" rel="noreferrer noopener" style={{color: 'blue', fontSize: '12px'}}>Google Privacy Control <IconExternalLink stroke={1.5}  size="12" /></a> &nbsp;&nbsp;
            </Card.Section>
          </Card>
          <Card shadow="sm" p="xl" mt="xl">
              <Grid>
                  <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <Title order={4} mt={10}>Embed Cookiex Script</Title>
                    <Text size="xs" w="90%" mt="xs">Choose your Cookie-blocking mode. Afterwards, copy the script tag and insert it as the very first script within the HEAD-tag of your website. The script tag will show the cookie banner and the privacy trigger on your website.</Text>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <Card withBorder shadow="sm" radius="md" bg="#f2f4fa" p="sm">
                        <Tabs defaultValue="autoBlock"  bg="#fff">
                            <Tabs.List>
                              <Tabs.Tab value="autoBlock">Script</Tabs.Tab>
                            </Tabs.List>
                            <Tabs.Panel value="autoBlock">
                            <Paper p="xs" shadow="0">
                                <Code color="white">
                                  <Text size="xs">{embScript}</Text>
                                </Code>
                             </Paper>
                             <Text mb="xs" mt="sm" ta="center" color="blue" style={{cursor: 'pointer'}} onClick={() => {
                                    clipboard.copy(embScript)
                                    showNotification({ color: "green", message: "Copied to clipboard !"});
                                  }
                               }>
                                <IconCopy size={18} /> {'Copy to clipboard'}
                              </Text>
                            </Tabs.Panel>
                        </Tabs>
                    </Card>
                  </Grid.Col>
              </Grid>
          </Card>
          <Card shadow="sm" p="xl" mt="xl">
               <Grid>
                 <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
                    <Title order={3}>Installation guides</Title>
                    <Text size="xs" mt="sm">Let us guide you through the implementation of cookie x to your website(s).</Text>
                    <Card shadow="0" bg="#f2f4fa" mt="xs">
                       <Group> <Text size="xs">Domain ID:</Text> <Textarea
                           value={domainId?domainId:''}
                           rightSectionPointerEvents="all" w="80%" autosize
                           rightSection={
                             <IconCopy style={{cursor: 'pointer'}} onClick={() => {
                                  clipboard.copy(domainId);
                                  showNotification({ color: "green", message: "Copied Domain ID !"});
                                 }
                             } />
                           }
                       ></Textarea>
                       </Group>
                   </Card>
                </Grid.Col>
              </Grid>
              <Grid>
                   {integrationData.map((stat, index) => (
                     <Grid.Col key={index} span={{ base: 12, md: 6, lg: 6 }}>
                         <div>
                           <Paper shadow="0" mt="xl" withBorder p="sm" radius="sm">
                             <Group justify="space-between">
                               <Group>
                                <Image src={stat.imgUrl} w="50" h="50" />
                                <Text>{stat.title}</Text>
                               </Group>
                               <div>
                                 <a href={stat.link} target="_blank" rel="noreferrer noopener" style={{color: 'blue', fontSize: '12px'}}><IconExternalLink stroke={1.5}  size="18" /> </a>
                               </div>
                             </Group>
                           </Paper>
                         </div>
                     </Grid.Col>
                   ))}
              </Grid>
          </Card>
        </Container>
      </>
    </React.Fragment>
  );
}

export { IntegrationPage };
