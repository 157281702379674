import React, { useEffect, useState } from 'react';
import { Select, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCaretDownFilled } from '@tabler/icons-react';

const DomainDropdown = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [domainData, setDomainData] = useState([]);
  const [currentSelection, setCurrentSelection] = useState(props.defaultSelection);

  const getDomainData = async () => {
    setLoading(true);
    try {
      const response = await props.consentService.getDomains();
      console.log("API Response:", response); // Log the response

      if (response && response.data) {
        const formattedData = response.data.map((domain: any) => ({
          value: domain.domainId || domain.domain,
          label: domain.domain || domain.domainId,
        }));

        setDomainData(formattedData);

        if (formattedData.length === 1) {
          const { value, label } = formattedData[0];
          setCurrentSelection(value);
          props.handleCurrentDomain(value, label);
        } else if (!props.defaultSelection && formattedData.length > 0) {
          const { value, label } = formattedData[0];
          setCurrentSelection(value);
          props.handleCurrentDomain(value, label);
        }
      }
    } catch (error) {
      console.error("Error fetching domains:", error);
      showNotification({ color: "red", message: "Failed to fetch domains." });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDomainData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Group gap={15} style={{ display: domainData.length > 0 ? 'block' : 'none' }}>
      {domainData.length > 0 && (
        <Select
          allowDeselect={false}
          value={currentSelection} // Bind the state value
          placeholder="Please select your Domain"
          data={domainData}
          onChange={(value: string | null, option: any) => {
            if (value) {
              setCurrentSelection(value);
              props.handleCurrentDomain(value, option.label);
            }
          }}
          disabled={loading}
          styles={{
            option: {
              textOverflow: 'ellipsis', // Enable ellipsis for dropdown items
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          }}
          rightSection={<IconCaretDownFilled size={15} />}
          rightSectionWidth={30} // Ensure adequate space for the icon
        />
      )}
    </Group>
  );
};

export default DomainDropdown;
