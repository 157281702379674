import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { IconCircleCheckFilled, IconCalendarClock } from "@tabler/icons-react";
import { Flex, Loader, LoadingOverlay, Mark, Avatar, Container, Title, Grid, Card, Text, Button, Group, Center, Modal, SimpleGrid, Paper } from '@mantine/core';
import { ConsentService } from "../../services/consentService";
import { ConsentOptionsConfig } from "../../Models/consentOptionsConfig";
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from "../../Context/configContext";
import { ScanService } from "../../services/scanService";
import { MantineReactTable, type MRT_ColumnDef } from 'mantine-react-table';

const scanSchedule : ConsentOptionsConfig[] =  [{checked: true,label: "Daily"},{checked: false,label: "Weekly"},{checked: false,label: 'Monthly'},{checked: false,label: "3 Months"}];

function PrivacyAuditsPage(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(props?.currentConsentData?.domainId || localStorage.getItem('activeDomainId'));
  const [necessaryCount, setNecessaryCount] = useState(0);
  const [preferencesCount, setPreferencesCount] = useState(0);
  const [statisticsCount, setStatisticsCount] = useState(0);
  const [marketingCount, setMarketingCount] = useState(0);
  const [unClassifiedCount, setUnClassifiedCount] = useState(0);
  const [scanHistory, setScanHistory] = useState<any[]>([]);
  const [opened, setOpened] = useState(false);
  const [frequency, setFrequency] = useState('Daily');
  const [loading, setLoading] = useState(false);
  const [lastScan, setLastScan] = useState<any>('Not Started Yet');
  const [nextScan, setNextScan] = useState('Not Started Yet');
  const [isProcessed, setIsProcessed] = useState('NOT_STARTED');
  const [totalScannedPages, setTotalScannedPages] = useState(0);
  const wsRef = useRef<WebSocket | null>(null);
  const consentService = new ConsentService(config!);
  const scanService = new ScanService(config!);

  useEffect(() => {
    if (props.currentConsentData) {
      setDomainId(props.currentConsentData.domainId);
    }
  }, [props.currentConsentData]);

  useEffect(() => {
    getDomainScanDataById(domainId);
    initializeWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
    };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);

  const saveFrequency = async () => {
    if (domainId) {
        setLoading(true);

        const data = { frequency: frequency };
        try {
            await consentService.saveScanFrequencyDomain(domainId, data);
            setOpened(false);
            showNotification({ color: "green", message: "Scan Schedule updated!" });
            getDomainScanDataById(domainId); // Call the function to refresh the data
        } catch (error) {
            console.error('Error saving scan frequency:', error);
            showNotification({ color: "red", message: "Failed to update scan schedule." });
        } finally {
            setLoading(false); 
        }
    }
  };

  const initializeWebSocket = () => {
    
    if (!domainId) 
      return;
    
    const token = localStorage.getItem("token");
    
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }

    const socketUrl = `${config!.scanWebsocketUrl}/${domainId}/scan?token=${token}`;
    const ws = new WebSocket(socketUrl);
    wsRef.current = ws;

    ws.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log("Message received:", data);

        if (data?.status === "Completed") {
          setIsProcessed("COMPLETED");
          showNotification({ color: "green", message: "Scan completed successfully!" });
          getDomainScanDataById(domainId);
        } else if (data?.status === "Progress") {
          setIsProcessed("PENDING");
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
      wsRef.current = null;
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      setIsProcessed('NOT_STARTED');
    };
  };

  const scanDomain = async () => {
      if(domainId) {
        await consentService.scanDomain(domainId);
        setIsProcessed('PENDING');
        showNotification({ color: "green", message: "Request is submitted for scan the domain" });
        if (wsRef.current) {
          wsRef.current.close();
          wsRef.current = null;
        }
    
        initializeWebSocket();
      }
  };

  const handleDownload = async () => {
      setLoading(true);

      try {
        const blob = await consentService.downloadCSVFile(domainId); // Call the service to get the blob

        // Process the blob and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'cookies-data.csv'; // File name for the CSV
        document.body.appendChild(a);
        a.click();
        a.remove();

        // Release the URL object to free up memory
        window.URL.revokeObjectURL(url);
      } catch (error) {
        showNotification({ color: "red", message: "Error downloading the CSV file." });
      } finally {
        setLoading(false);
      }
  };

  const getDomainScanDataById = async (id: string) => {
    if(id) {
      try {
        setLoading(true);
        const res = await scanService.getScanReport(id);
        if(res){
          setNecessaryCount(res?.latestScanResult?.necessaryCookies || 0);
          setPreferencesCount(res?.latestScanResult?.preferenceCookies || 0);
          setStatisticsCount(res?.latestScanResult?.statisticsCookies || 0);
          setMarketingCount(res?.latestScanResult?.marketingCookies || 0);
          setUnClassifiedCount(res?.latestScanResult?.unclassifiedCookies || 0);
          setLastScan(new Date(res?.latestScanResult?.scannedOn).toLocaleDateString());
          setScanHistory(res?.history);

          if (res?.nextScan) {
            setNextScan(new Date(res.nextScan).toLocaleDateString());
          } else {
            setNextScan("No scan date available");
          }

          if(res?.status) {
            setIsProcessed(res.status === 'Progress' ? 'PENDING' : res.status);
          }

          if(res?.latestScanResult?.pages) {
            setTotalScannedPages(res?.latestScanResult?.pages);
          }
        } else {
          clearFields();
        }
      } catch (error) {
        console.error("Error fetching domain scan data:", error);
        clearFields(); // Ensure fields are cleared on error
        showNotification({ color: "red", message: "Failed to fetch domain scan data." });
      } finally {
        setLoading(false);
      }
    }
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      { accessorKey: "scannedOn", header: "Audit Time", 
          Cell: ({ cell }) => new Date(cell.getValue() as string).toLocaleString() 
      },
      { accessorKey: "status", header: "Status", 
          Cell: ({ cell }) => 
            <Mark p={5} style={{ textTransform: "capitalize" }}>
              {cell.getValue() as string}
            </Mark>
      },
      { accessorKey: "pages", header: "Pages Scanned" },
      { accessorKey: "cookiesCount", header: "# Trackers" },
    ],
    []
  );

  const clearFields = () => {
    setNecessaryCount(0);
    setPreferencesCount(0);
    setStatisticsCount(0);
    setMarketingCount(0);
    setUnClassifiedCount(0);
    setScanHistory([]);
    setLastScan("Not Started Yet");
    setNextScan("Not Started Yet");
    setIsProcessed("NOT_STARTED");
    setTotalScannedPages(0);
  };

  const navigate = useNavigate();

  return (
   <React.Fragment>
      {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
     <>
        <Container fluid>
          <Card bg="#F4F6FB" p="0" mb="md" mt="md">
              <Group justify="space-between">
                <Title order={2} mb="sm">Privacy Audits</Title>
              </Group>
          </Card>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card shadow="sm" p="lg" style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}>
                <Card.Section p="md" withBorder>
                    <Group justify="right">
                        {isProcessed != 'PENDING' && (
                            <Button onClick={() => scanDomain()} variant="filled" size="xs">Launch Next Audit</Button>
                        )}

                         {isProcessed == 'PENDING' && (
                          <Flex>
                              <Loader color="blue" size="xs" />
                            <Text size="sm" c="orange" fw={500}> &nbsp; Audit In Progress</Text>
                            </Flex>
                         )}
                    </Group>
                </Card.Section>
                <Card.Section p="lg">
                    <Title order={6} fw="500">Latest Audit</Title>
                        {lastScan && (
                                <Text size="sm">{lastScan}</Text>
                        )}
                    <Title order={6}  fw="500" mt="md">Next Audit</Title>
                    <Text size="sm">
                        {nextScan && (
                            <span>{nextScan} &nbsp;</span>
                        )}
                        <span style={{color:'blue', cursor: 'pointer'}} onClick={() => setOpened(true)}>Schedule</span>
                    </Text>
                    <Title order={6}  fw="500" mt="md">Scanned pages</Title>
                    <Text size="sm" c="blue" style={{cursor:'pointer'}} onClick={handleDownload}>{totalScannedPages} pages (Download CSV)</Text>
                </Card.Section>
              </Card>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <Card shadow="sm" p={20} style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}>
                <Card.Section p="lg" withBorder>
                    <Text size="sm" fw={500}>Trackers</Text>
                </Card.Section>
                <Card.Section p="lg" withBorder>
                    <Grid>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Necessary :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{necessaryCount}</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Preference :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{preferencesCount}</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Statistics :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{statisticsCount}</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Marketing :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{marketingCount}</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 4, lg: 4 }}><Text size="sm">Unclassified :</Text></Grid.Col>
                      <Grid.Col span={{ base: 12, md: 6, lg: 6 }}><Text size="sm">{unClassifiedCount}</Text></Grid.Col>
                    </Grid>
                </Card.Section>
              </Card>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
              <Card shadow="sm" padding="lg" style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}>
                <Card.Section p="md">
                    <Text size="lg" fw={500}>Audit History</Text>
                </Card.Section>
                {scanHistory.length > 0 ? (
                  <MantineReactTable
                    columns={columns}
                    data={scanHistory}
                    state={{ isLoading: loading }}
                    enablePagination
                    renderToolbarInternalActions={() => {return <><div></div></>}}  
                    mantineTableBodyRowProps={({ row }) => ({
                      onClick: (e: React.MouseEvent<HTMLTableRowElement>) => {
                        const scan = row.original;
                        if (e.metaKey || e.ctrlKey) {
                          window.open(`/privacy-audit-details?id=${scan.id}`, "_blank");
                        } else {
                          navigate(`/privacy-audit-details?domainId=${domainId}&id=${scan.id}`);
                        }
                      },
                      style: {
                          cursor: 'pointer', // Change cursor to pointer on row hover
                      },
                    })}
                    mantineSearchTextInputProps={{
                      placeholder: "Search...",
                      variant: "filled",
                    }}
                    mantineTableBodyCellProps={{
                      style: { padding: "12px", borderBottom: "1px solid #eee" },
                    }}
                    mantineTableHeadCellProps={{
                      style: {
                        backgroundColor: "#f9f9f9",
                        fontWeight: 600,
                        borderBottom: "2px solid #0056b3",
                        padding: "12px",
                      },
                    }}
                  />
                ) : (
                  <Text size="sm" c="dimmed" ta="center">
                    No audit data available. Please initiate a scan to see results.
                  </Text>
                )}
              </Card>
            </Grid.Col>
          </Grid>
          <Modal withCloseButton={false} opened={opened} onClose={() => setOpened(false)} size="md" centered>
              <Center>
                <Avatar color="blue" radius="xl" bg="blue">
                   <IconCalendarClock color="white"  />
                </Avatar>
              </Center>
              <Center>
                <Title order={2} ta="center" mt="md">Schedule Automatic Scans</Title>
              </Center>
              <Center mt="sm">
                <Text size="xs" ta="center" pl="30" pr="30">
                    Your website scan will run automatically with a frequency set below. You can also initiate a scan here.
                </Text>
              </Center>
              <Center mt="md">
                   <SimpleGrid cols={4}>
                      {scanSchedule.map((sc, index) => (
                       <Paper key={index} shadow="0px" pt="xs" pb="xs" pl="xs" pr="xs" withBorder style={{ position: "relative",cursor:"pointer" }} onClick={(event) => {setFrequency(sc.label === '3 Months'?'ThreeMonths':sc.label)}}>
                           <Text size="xs">{sc.label}</Text>
                           {frequency == (sc.label === '3 Months'?'ThreeMonths':sc.label) && (
                              <IconCircleCheckFilled className="primary"
                                  size="1rem"
                                  stroke={3}
                                  style={{ left: "-8px", top: "-7px", position: "absolute" }}
                              />
                           )}
                        </Paper>
                      ))}
                   </SimpleGrid>
              </Center>
              <Center mt="xl">
                <Button w="200" onClick={() => saveFrequency()}>Save</Button>
              </Center>
          </Modal>
        </Container>
    </>
   </React.Fragment>
  );
};

export { PrivacyAuditsPage };
