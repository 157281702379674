import {
  Grid,
  Paper,
  Text,
  Title,
  Group,
  Stack,
  Button,
  Center,
  BackgroundImage,
  SimpleGrid,
  Select,
  Accordion,
  Radio,
  Checkbox,
  Divider,
  Tooltip,
  Flex,
  Popover,
} from "@mantine/core";
import {
  IconCircleCheckFilled,
  IconLayout,
  IconPalette,
  IconUpload,
  IconInfoCircle,
  IconLanguage,
} from "@tabler/icons-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import colorgradient from "../../../images/colorgradient.png";
import { Dropzone, MIME_TYPES, FileWithPath } from "@mantine/dropzone";
import { BannerTheme } from "./BannerTheme";
import { ButtonTheme } from "./ButtonTheme";
import { ThemeDetails } from "../../../Models/themeDetails";
import { ConsentConfig } from "../../../Models/consentConfig";
import './consent.css';
import { ConsentService } from "../../../services/consentService";
import { ConfigContext } from "../../../Context/configContext";
import { regulations, layouts, finalConsentConfig, themesConfig, languageOptions } from "../../../utils/utils";
import { useDisclosure } from "@mantine/hooks";

declare var Cookiex: {
  new (): {
      init(theme: { domainId: string; domainName: string }): void;
  };
};

function Step5(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [layoutType, setLayoutType] = useState(props.consentConfigData?.theme?.layout || "Box");
  const [themeType, setThemeType] = useState(props.consentConfigData?.theme?.type || "Light");
  const [buttonStyle, setButtonStyle] = useState(props.consentConfigData?.theme?.theme?.buttonStyle || "Mixed");
  const [bannerValue, setBannerValue] = useState(props.consentConfigData?.theme?.alignment || "leftBottomPopUp");
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [tempFile, setTempFile] = useState<FileWithPath[]>([]);
  const [isLogoShown, SetLogoShown] = useState(true);
  const [language, setLanguage] = useState(props.consentConfigData?.language || 'en');
  const [customThemeStyle, setCustomThemeStyle] = useState<any>(props.consentConfigData?.theme || finalConsentConfig);
  const [consentConfig, setConsentConfig] = useState<ConsentConfig>(props.consentConfigData?.theme || finalConsentConfig);
  const initialRef = useRef(false);
  const [regulation, setRegulation] = useState<any>(props.consentConfigData?.theme?.regulation || regulations[0]);
  const [opened, { close, open }] = useDisclosure(false);

  const consentService = new ConsentService(config!);

  useEffect(() => {
       if(props.consentConfigData?.theme) {
           setCustomThemeStyle(props?.consentConfigData?.theme);
           setConsentConfig(props?.consentConfigData?.theme);
           setLayoutType(props?.consentConfigData?.theme?.layout || "Box");
           setBannerValue(props?.consentConfigData?.theme?.alignment || "leftBottomPopUp");
           setThemeType(props?.consentConfigData?.theme?.type || "Light");
           setButtonStyle(props?.consentConfigData?.theme?.theme?.buttonStyle || "Mixed");
           SetLogoShown(props?.consentConfigData?.isLogoDisplay);
           setLanguage(props?.consentConfigData?.theme.language || 'en');
       }
       setTempFile([]);
       console.log(tempFile);
       if(!initialRef.current) {
          initialRef.current = true;
          generatePreview(true, regulation, true);
       }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.consentConfigData]);
  
  const generatePreview = (isPreview:boolean, regulationType:any, initialPreview:boolean) => {
      consentConfig.isLogoDisplay = isLogoShown;
      consentConfig.regulation = regulationType;
      if(isPreview) {
        generateHTML(regulationType, initialPreview);
      }
  };

  const handleLanguage = (languageObj:any) => {
    setLanguage(languageObj);
    setTempFile([]);
    consentConfig.language = languageObj;
    generatePreview(true, regulation, false);
  };

  const generateHTML = (regulationType:any, initialPreview:boolean) => {
      const loadExternalScript = (src:any) => {
        return new Promise<void>((resolve, reject) => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = src;

          script.onload = () => {
              resolve();
          };

          script.onerror = () => {
              reject(new Error(`Failed to load script ${src}`));
          };

          document.head.appendChild(script);
        });
      }

      const scriptUrl = config?.bannerUrl + '/cookiex.min.js';

      setTimeout(function () {
        loadExternalScript(scriptUrl)
        .then(() => {
            const theme : any = {
                domainId: props.domainId,
                domainUrl: props.domainName,
                selectorId:'coookiex-comp-banner-preview',
                theme: {
                  layout : consentConfig.layout,
                  alignment : consentConfig.alignment,
                  theme : consentConfig?.theme,
                  type  : consentConfig.type,
                  regulation : regulationType,
                },
                initialPreview: initialPreview
            };

            const cookiex = new Cookiex();
            cookiex.init(theme);
        })
        .catch((error) => {
            console.error('Error loading external script:', error);
        });
      }, 1000);
  };

  const handleCustomThemeStyles = (styleType:any) => {
    const filteredTheme = themesConfig.filter(theme => theme.type === styleType);
    setCustomThemeStyle(filteredTheme[0]);
    consentConfig.theme = filteredTheme[0]; 
    consentConfig.type = styleType;
    generatePreview(true, regulation, false);
    setTempFile([]);
  };

  const handleButtonStyles = (styleType: any) => {
    setButtonStyle(styleType); // Update the button style state
  
    // Create a shallow copy of the custom theme style object
    const updatedThemeStyle = { ...customThemeStyle };
  
    // Set button variants based on styleType
    const variant = styleType === "Outline" ? "outline" : "filled";
    updatedThemeStyle.buttonCustomizeVariant = variant;
    updatedThemeStyle.buttonAcceptVariant = variant;
    updatedThemeStyle.buttonRejectVariant = variant;
  
    // Set text color based on the theme type and styleType
    let textColor = "#fff";
    if (customThemeStyle.type === "Light" && styleType === "Outline") {
      textColor = "#0078b4";
    } else if (customThemeStyle.type === "Light" && styleType !== "Outline") {
      textColor = "#fff";
    }
  
    updatedThemeStyle.buttonCustomizeTextColor = textColor;
    updatedThemeStyle.buttonAcceptTextColor = textColor;
    updatedThemeStyle.buttonRejectTextColor = textColor;
  
    // Update the customThemeStyle state
    setCustomThemeStyle(updatedThemeStyle);
  
    // Update the consentConfig object and generate the preview
    const updatedConsentConfig = { ...consentConfig, theme: updatedThemeStyle };
    setConsentConfig(updatedConsentConfig);
    generatePreview(true, regulation, false);
  };
  
  const handleCustomStyles = (colorValue:any, type:any) => {
    const style = `${type}`;
    customThemeStyle.theme[style] = colorValue;
    setCustomThemeStyle(customThemeStyle);
    generatePreview(true, regulation, false);
    setTempFile([]);
  };

  const handleCustomButtonStyles = (colorValue:any, buttonType:any, type:any) => {
        const style = `${buttonType}${type}`;
        customThemeStyle.theme[style] = colorValue;
        setCustomThemeStyle(customThemeStyle);
        generatePreview(true, regulation, false);
        setTempFile([]);
  };

  const handleUpload = (file:any) => {
     setFiles(file);
     setTempFile(file);
     consentService.uploadClientLogo(props.domainId, file);
  };

  const saveConsentBannerConfig = async () => {
      props.consentData(consentConfig);
  };
  

  return (
    <React.Fragment>
      <>
        <Grid>
          <Grid.Col span={{ base: 12, md: 5, lg: 3 }} style={{ borderRadius: "8px", background:"#fff"}}>
            <div>
              <Paper withBorder shadow="0">
                <Accordion transitionDuration={0} defaultValue="layout">
                  <Accordion.Item value="layout">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconLayout />
                        <Text size="xs"> Layout </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Text size="xs" mb="xs" mt="xs" fw={500}>Consent Template</Text>
                      <Flex>                      
                        <Select mb={10} mr={10}
                          placeholder="Choose a regulation"
                          allowDeselect={false}
                          searchable
                          data={regulations.map((reg) => (
                              { value: reg.value, label: reg.label }
                          ))}
                          defaultValue={regulation.value}
                          onChange={(value) => {
                            const selectedRegulation = regulations.find((reg) => reg.value === value);
                            setRegulation(selectedRegulation);
                            console.log('Selected Regulation:', selectedRegulation);
                            generatePreview(true, selectedRegulation, false);
                          }}
                        />
                        <Popover width={300} position="right" withArrow shadow="md" opened={opened}>
                          <Popover.Target>
                            <IconInfoCircle onMouseEnter={open} onMouseLeave={close} />
                          </Popover.Target>
                          <Popover.Dropdown style={{ pointerEvents: 'none' }}>
                            <Text size="sm">{regulation?.description}</Text>
                          </Popover.Dropdown>
                        </Popover>
                      </Flex>
                      <Text size="xs" mb="xs" fw={500}>Banner Layout{" "}</Text>

                      <Grid>
                        {layouts.map((layout:any, index) => (
                          <Grid.Col key={index} span={{ base: 12, md: 4, lg: 4 }}>
                            <div>
                              <Paper
                                shadow="0px"
                                withBorder
                                p="lg"
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: "60px",
                                }}
                                onClick={() => {
                                  setLayoutType(layout.layoutType);
                                  setBannerValue(layout.bannerValue);
                                  consentConfig.layout = layout.layoutType;
                                  consentConfig.alignment = layout.bannerValue;
                                  generatePreview(true, regulation, false);
                                }}
                              >
                                <div
                                  className="fill-primary"
                                  style={layout.styles}
                                ></div>
                                {layoutType === layout.layoutType && (
                                  <IconCircleCheckFilled
                                    className="primary"
                                    size="1rem"
                                    stroke={3}
                                    style={{ left: "-8px", top: "-7px", position: "absolute" }}
                                  />
                                )}
                              </Paper>
                              <Text size="xs" mt={5} ta="center">
                                {layout.text}
                              </Text>
                            </div>
                          </Grid.Col>
                        ))}
                      </Grid>
                      {layoutType == "Box" && (
                        <Radio.Group mt={15} mb={15} value={bannerValue}
                        onChange={(e : any) => {
                          setBannerValue(e);
                          consentConfig.alignment = e;
                          generatePreview(true, regulation, false);
                        }}>
                          <SimpleGrid cols={2}>
                            <Radio size="xs" value="leftBottomPopUp" label="Bottom Left" />
                            <Radio size="xs" value="rightBottomPopUp" label="Bottom Right" />
                            <Radio size="xs" value="leftTopPopUp" label="Top Left" />
                            <Radio size="xs" value="rightTopPopUp" label="Top Right" />
                          </SimpleGrid>
                        </Radio.Group>
                      )}
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="design">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconPalette />
                        <Text size="xs"> Design </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Select mt={10} label="Theme" allowDeselect={false} defaultValue={props.consentConfigData?.theme?.type} onChange={(event) => handleCustomThemeStyles(event)} data={["Light", "Dark", "Custom"]} />
                      <div>
                      {customThemeStyle?.type == 'Custom' && (
                        <div>
                            <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle.theme.background} name="background" label="Background" description="" />
                            <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle.theme.textColor} name="textColor" label="Text Color" description="Choose the color of all texts within the banner" />
                            <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle.theme.highlight} name="highlight" label="Highlight" description="Choose your highlight color that will impact all links and active toggles in your banner" />
                            <Text mt="sm" size="xs" ta="center" c="blue" onClick={() => handleCustomThemeStyles('Light')}>Reset all banner consent</Text>
                            <Divider my="sm" />
                            <Text mt="sm" size="xs" c="blue">Button Colors</Text>
                            <Accordion transitionDuration={0} variant="contained" mt={5}>
                              <Accordion.Item value="customize" mt={5}>
                                <Accordion.Control>
                                  <Group justify="left"><Text size="xs"> Button 1 (Customize) </Text></Group>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.theme.buttonCustomizeBackGround} label="BackGround" type="buttonCustomize" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.theme.buttonCustomizeTextColor} label="TextColor" type="buttonCustomize" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.theme.buttonCustomizeBorder} label="Border" type="buttonCustomize" description="" />
                                </Accordion.Panel>
                              </Accordion.Item>
                              <Accordion.Item value="reject" mt={5}>
                                <Accordion.Control>
                                  <Group justify="left">
                                    <Group justify="left"><Text size="xs"> Button 2 (Reject All) </Text></Group>
                                  </Group>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.theme.buttonRejectBackGround} label="BackGround" type="buttonReject" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.theme.buttonRejectTextColor} label="TextColor" type="buttonReject" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.theme.buttonRejectBorder} label="Border" type="buttonReject" description="" />
                                </Accordion.Panel>
                              </Accordion.Item>
                              <Accordion.Item value="accept" mt={5}>
                              <Accordion.Control>
                                <Group justify="left">
                                  <Group justify="left"><Text size="xs"> Button 2 (Accept) </Text></Group>
                                </Group>
                              </Accordion.Control>
                              <Accordion.Panel>
                                   <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.theme.buttonAcceptBackGround} label="BackGround" type="buttonAccept" description="" />
                                   <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.theme.buttonAcceptTextColor} label="TextColor" type="buttonAccept" description="" />
                                   <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.theme.buttonAcceptBorder} label="Border" type="buttonAccept" description="" />
                              </Accordion.Panel>
                            </Accordion.Item>
                           </Accordion>
                        </div>
                      )}
                      {customThemeStyle?.type != 'Custom' && (
                        <Select mt={10} allowDeselect={false} label="Button Style" defaultValue={buttonStyle} onChange={(event) => handleButtonStyles(event)}  data={["Outline", "Mixed", "Solid"]} />
                      )}
                      </div>
                      <Group justify="left" mt={10}>
                          <Title order={6} fw={500}>Logo</Title>
                          <Tooltip label="Add a logo to display on your banner">
                            <IconInfoCircle size="14"/>
                          </Tooltip>
                      </Group>
                      <Checkbox mt={4}
                        mb={10}
                        checked={isLogoShown}
                        onChange={(event) => SetLogoShown(event.currentTarget.checked)}
                        label="Display logo on banner"
                      />
                      <Dropzone
                        maxFiles={1}
                        accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.svg, MIME_TYPES.gif]}
                        onDrop={handleUpload}
                      >
                        <Group justify="center">
                          <IconUpload size={15} color="blue" />
                          <Text size="xs" ta="center" c="blue">
                            Upload File
                          </Text>
                        </Group>
                      </Dropzone>
                      <Text size="xs" mt={10}>
                        Filetype jpg, jpeg, gif, svg
                      </Text>
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="content">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconLanguage />
                        <Text size="xs"> Content </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      {languageOptions.length > 0 && (
                          <Select mt={10} allowDeselect={false} defaultValue={language} label="Language" data={languageOptions} onChange={(event) => handleLanguage(event)} />
                      )}
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </Paper>
            </div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 7, lg: 9 }}>
            <div style={{ width: "100%", position: "relative", maxWidth: '1000px !important' }}>
              <BackgroundImage 
                  w="100%" 
                  mih={450} 
                  src={colorgradient} 
                  radius="sm"
                  style={{ 
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                  }}
              >
                  <div style={{
                      height: '100%', 
                      width: '100%',
                      minHeight: '450px'
                  }} id="coookiex-comp-banner-preview" />
              </BackgroundImage>
            </div>
            <Group>
                <Button variant="filled" mt="xs" size="sm" onClick={saveConsentBannerConfig}>
                  Publish Changes
                </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </>
    </React.Fragment>
  );
}

export { Step5 };
