import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Text,
  Grid,
  Title,
  LoadingOverlay,
  Modal,
  Group,
  Button,
  Loader,
  Divider,
} from '@mantine/core';
import './pricing.css';
import { PricingService } from "../../services/pricingService";
import { ConfigContext } from '../../Context/configContext';
import { useTitle } from '../hooks/useTitle';
import { showNotification } from '@mantine/notifications';
import { useLocation } from "react-router-dom"; // Import useLocation to get the query parameters

export const APP_URL = "https://app.cookiex.io";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
// Headings
export const PRICING_HEADINGS = {
  top: "Pricing",
  title: "Choose a Plan That Works For You",
  subtitle: "14-day free trial. No fee till your trial ends. Cancel anytime.",
};


const PricingScreen = (props:any) => {
  const { config } = useContext(ConfigContext);
  const pricingService = useMemo(() => new PricingService(config!), [config]);
  const [loading, setLoading] = useState(false);
  const [subscriptionLoader, setSubscriptionLoader] = useState(false);
  const [activeTab, setActiveTab] = useState<any>('monthly');
  const [planData, setPlanData] = useState<any>(null);
  const initialRef = useRef(false);
  const query = useQuery();
  const domainId = query.get("domainId"); // Get the domainId from the query parameters
  const [opened, setOpened] = useState(false);
  const [cancelOpened, setCancelOpened] = useState(false);
  const [downGradeOpened, setDownGradeOpened] = useState(false);
  const [subUrl, setSubUrl] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState<any>(null);
  const [actionType, setActionType] = useState<any>(null);

  useTitle("Pricing");

   const getPlans = useCallback(async () => {
      setLoading(true);
      try {
          const response = await fetch(config!.pricingUrl);
          const data = await response.json();
          setPlanData(data);
      } catch (error) {
          console.error("Error fetching domains:", error);
          showNotification({ color: "red", message: "Failed to fetch Plans." });
      } finally {
          setLoading(false);
      }
  }, [pricingService, domainId]);

  useEffect(() => {
    if(!initialRef.current) {
      initialRef.current = true;
      getPlans();
    }
  }, [getPlans]);

  const onSelectPlan = useCallback((planId:any, action:any, provider:any) => {
      setActionType(action);
      setSelectedPlanId(planId);
      if(action === "Cancel") {
        setCancelOpened(true);
      } else if(action === "Downgrade" || action === "Upgrade" ) {
        setDownGradeOpened(true);
      } else {
        setOpened(true); // Open modal immediately
        setSubscriptionLoader(true); // Show loader
        setTimeout(() => {
          const fetchPlan = async () => {
            try {
              console.log(planData);
              const payload = {
                planId,
                provider,
              };
    
              const result = await pricingService.savePlan(payload, domainId as string);
  
              if (result.subUrl) {
                getPlans();
                setSubUrl(result.subUrl); // Set the short URL
                showNotification({ color: "green", message: "Plan selected successfully!" });
              } else {
                throw new Error("No shortUrl returned in response.");
              }
            } catch (error) {
              showNotification({ color: "red", message: "Failed to select plan. Please try again." });
              setOpened(false); // Close modal on failure
            } finally {
              setSubscriptionLoader(false); // Stop loader after delay
            }
          };
    
          fetchPlan(); // Call the async function
        }, 1000); // Simulated delay of 1 second
      } 
    },
    [activeTab, domainId, pricingService, getPlans]
  );
  

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(subUrl);
    showNotification({ color: "green", message: "URL copied to clipboard!" });
  };

  const handleOpenLink = () => {
    if (subUrl) {
      window.open(subUrl, '_blank', 'noopener,noreferrer');
    } else {
      showNotification({ color: "red", message: "Payment link is not available." });
    }
  };

  const handleCancel = async () => {
    if(domainId) {
      try {
        await pricingService.deletePlan(domainId);
        showNotification({ color: "green", message: "Plan deleted successfully!" });
        await getPlans();
        setCancelOpened(false);
      } catch (error) {
        console.error("Error canceling subscription:", error);
      }
    }
  };

  const handleDownGrade = async () => {
    if(domainId) {
      try {
        const payload = {planId: selectedPlanId, remainingCount: activeTab === 'yearly' ? 12 : 1};
        const result = await pricingService.updatePlan(domainId, payload);
        console.log(result);
        await getPlans();
        setDownGradeOpened(false);
      } catch (error) {
        console.error("Error canceling subscription:", error);
      }
    }
  };

  const getCurrency = (price: number, currency: string = "USD") => {
    return new Intl.NumberFormat(document.documentElement.lang || window.navigator.language, {
      style: "currency",
      currency,
      minimumFractionDigits: 0, // No decimal places
      maximumFractionDigits: 0,
    }).format(price);
  };
  
  return (
    <React.Fragment>
      {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
    <>
    <div className="container">
        <div className="heading f-heading text-center">
            <Title order={4} className="top-hd">{PRICING_HEADINGS.top}</Title>
            <Title className='h2' order={2}>{PRICING_HEADINGS.title}</Title>
            <Text size='md'>{PRICING_HEADINGS.subtitle}</Text>
        </div>
        <ul className={`pricing-tabs ${activeTab === 'monthly' ? '' : 'rmv-ofr'}`}>
          <li
            className={`pricing-tab-link ${activeTab === 'monthly' ? 'current' : ''}`}
            onClick={() => setActiveTab('monthly')}
          >
          Monthly
          </li>
          <li
            className={`pricing-tab-link ${activeTab === 'yearly' ? 'current' : ''}`}
            onClick={() => setActiveTab('yearly')}
          >
            Yearly
          </li>
        </ul>

        <div id={`pricing-tab-${activeTab}`} className="pricing-tab-content current">
          {planData && planData.plans && Object.keys(planData.plans).length > 0 ? (
            <Grid className="row pricing-cards">
              {Object.entries(planData.plans).map(([planKey, plan]: any, index: number) => (
                <Grid.Col
                  key={index}
                  className={`prc-col ${planKey === "pro" ? "special" : ""}`}
                  span={{ base: 12, md: 6, lg: 3 }}
                >
                  <div className="p-card">
                    <div style={{padding:'25px'}}>
                      <Title className="h4" order={4}>
                        {planKey.charAt(0).toUpperCase() + planKey.slice(1)}
                      </Title>
                      <Text className="p" style={{marginTop:'20px'}}>
                        {planKey === "free" && "For blogs and personal websites"}
                        {planKey === "basic" && "For niche audience or startups"}
                        {planKey === "pro" && "For small to medium sized businesses"}
                        {planKey === "ultimate" && "For large businesses with high traffic"}
                      </Text>
                      <Title className="h3" order={3} style={{marginTop:'20px'}}>
                        {getCurrency(plan[activeTab].price, "INR")}{" "}
                        <span className="sm">/{activeTab}</span>
                      </Title>
                      <Button
                        onClick={() => onSelectPlan(plan[activeTab].planId, "Get Started", planData.payment_gw)}
                        className="get-started my-btn my-btn-2"
                      >
                        Get Started
                      </Button>
                    </div>
                    <div style={{padding:'0px 25px 0px 25px'}}>
                    <Divider size="sm" />
                    </div>
                    <ul className="tab-list">
                      {planKey === "free" && (
                        <>
                          <li>5,000 pageviews/month</li>
                          <li>Basic customization</li>
                        </>
                      )}
                      {planKey === "basic" && (
                        <>
                          <li>50,000 pageviews/month</li>
                          <li>Custom branding</li>
                        </>
                      )}
                      {planKey === "pro" && (
                        <>
                          <li>2,50,000 pageviews/month</li>
                          <li>Geo-targeted cookie banner</li>
                        </>
                      )}
                      {planKey === "ultimate" && (
                        <>
                          <li>Unlimited pageviews</li>
                          <li>Geo-targeted cookie banner</li>
                        </>
                      )}
                    </ul>
                  </div>
                </Grid.Col>
              ))}
            </Grid>
          ) : (
            <Text className="no-plans">No plans available for {activeTab}.</Text>
          )}
        </div>
        <Modal withCloseButton={false} opened={opened} onClose={() => setOpened(false)} centered>
        <>
          {subscriptionLoader && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
              <Loader variant="bars" color='green' />
            </div>
            
          )}
          {!subscriptionLoader && (
            <div>
              <Title order={3} className="text-center">Complete Your Subscription</Title>
              <Text size="sm" className="text-center" mt={20} mb={20}>
                Use the link below to complete your payment:
              </Text>
              <Group justify="center" mt={20}>
                <Button variant="filled" color="green" onClick={handleOpenLink}>
                  Open Payment Link
                </Button>
                <Button variant="outline" color="blue" onClick={handleCopyUrl}>
                  Copy URL
                </Button>
              </Group>
            </div>
          )}
        </>
      </Modal>
      <Modal withCloseButton={false} opened={cancelOpened} onClose={() => setCancelOpened(false)} centered>
        <Title order={3} className="text-center">Cancel Subscription</Title>
        <Text size="sm" className="text-center" mt={20} mb={20}>
          Are you sure you want to cancel your subscription? This action cannot be undone.
        </Text>
        <Group justify="center" mt={20}>
          <Button variant="filled" color="red" onClick={handleCancel}>
            Confirm
          </Button>
          <Button variant="outline" color="blue" onClick={() => setCancelOpened(false)} >
            Cancel
          </Button>
        </Group>
      </Modal>
      <Modal withCloseButton={false} opened={downGradeOpened} onClose={() => setDownGradeOpened(false)} centered>
        <Title order={3} className="text-center"> {actionType === 'Upgrade' ? 'Upgrade' : 'Downgrade'} Subscription</Title>
        <Text size="sm" className="text-center" mt={20} mb={20}>
          Are you sure you want to {actionType === 'Upgrade' ? 'Upgrade' : 'Downgrade'} your subscription? This action cannot be undone.
        </Text>
        <Group justify="center" mt={20}>
          <Button variant="filled" color="red" onClick={handleDownGrade}>
            Confirm
          </Button>
          <Button variant="outline" color="blue" onClick={() => setDownGradeOpened(false)}>
            Cancel
          </Button>
        </Group>
      </Modal>
      </div>
    </>
    </React.Fragment>
  );
};

export { PricingScreen };
