import {
  Text,
  Container,
  Space,
  Title,
  Group,
  Card,
  Tabs,
  SimpleGrid,
  Box,
  Badge,
  Table,
  Button,
  Center
} from "@mantine/core";
import { IconCrown} from "@tabler/icons-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { MantineReactTable, MRT_ColumnDef } from "mantine-react-table";
import { Link, useNavigate } from "react-router-dom";
import { ProfileInformation } from "./ProfileInformation";
import { ChangePassword } from "./ChangePassword";
import { DeleteAccount } from "./DeleteAccount";
import { PricingService } from "../../services/pricingService";
import { ConfigContext } from '../../Context/configContext';

function ProfilePage(props:any): JSX.Element {
  const router = useNavigate();
  const { config } = useContext(ConfigContext);
  const pricingService = useMemo(() => new PricingService(config!), [config]);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [domainId, setDomainId] = useState<any | null>(props?.currentConsentData?.domainId || localStorage.getItem('activeDomainId'));
 
  useEffect(() => {
    if (props.currentConsentData) {
      setDomainId(props.currentConsentData.domainId);
    }
  }, [props.currentConsentData]);
  

  const fetchSubscriptions = async (domainId:any) => {
    setLoading(true);
    try {
      const response : any = await pricingService.getSubscriptions(domainId);      
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptions(domainId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      { accessorKey: "category", header: "Category" },
      {
        accessorKey: "price",
        header: "Price",
        Cell: ({ row }) => (
          <Text size="sm" fw={700}>
            ${row.original.price.toFixed(2)}
          </Text>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row }) => (
          <Badge
            variant="light"
            color={row.original.status === "Active" ? "green" : "orange"}
          >
            <Text tt="capitalize" size="xs" fw={700}>
              {row.original.status}
            </Text>
          </Badge>
        ),
      },
      {
        header: "Action",
        Cell: ({ row }) => (
          <Link to={`/pricing/?domainId=${domainId}`}>
          <Button
              size="xs"
              variant="outline"
              leftSection={<IconCrown />}
            >
              Upgrade
            </Button>
          </Link>
        ),
      },
    ],
    [domainId]
  );

  return (
    <React.Fragment>
      <Container fluid>
        <Space h="lg" />
        <Title order={2}>Profile</Title>
        <Space h="lg" />
        <Card withBorder shadow="sm" radius="md">
          <Card.Section withBorder inheritPadding mt="sm" pb="md">
            <Tabs defaultValue="account">
              <Tabs.List>
                <Tabs.Tab value="account">Account</Tabs.Tab>
                <Tabs.Tab value="changePassword">Change Password</Tabs.Tab>
                <Tabs.Tab value="subscription">Subscription</Tabs.Tab>
                <Tabs.Tab value="invoices">Invoices</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="account">
                <ProfileInformation />
              </Tabs.Panel>

              <Tabs.Panel value="changePassword">
                <ChangePassword />
              </Tabs.Panel>

              <Tabs.Panel value="subscription">
                <Card shadow="0">
                  <Card.Section withBorder inheritPadding py="xs">
                    <Group justify="space-between">
                        <Text fw={700}>Your Subscriptions</Text>
                        {localStorage.getItem("activeDomainId") && (
                            <Group ml={50} gap={5}>
                              <Button variant="filled" size="xs" onClick={() => {router(`/pricing?domainId=${localStorage.getItem("activeDomainId")}`)}}>
                                <IconCrown stroke={1.3} /> &nbsp; <Text size="xs"> Upgrade{" "} </Text>
                              </Button>
                            </Group>
                        )}
                    </Group>
                    <SimpleGrid cols={1} mt={10} mb={20}>
                      <Box>
                        {data.length > 0 ? 
                          <MantineReactTable
                            columns={columns}
                            data={data}
                            state={{ isLoading: loading }}
                            enablePagination
                            enableColumnActions={false}
                            initialState={{
                              showGlobalFilter: true,
                            }}
                            mantineSearchTextInputProps={{
                              placeholder: "Search Subscriptions",
                              style: { minWidth: "18rem", display: "block" },
                              variant: "filled",
                            }}
                            renderToolbarInternalActions={() => (
                              <div style={{ width: "200px", marginLeft:'20px' }}>
                                  </div>
                            )}
                            mantineTableHeadCellProps={{
                              style: {
                                backgroundColor: "#f9f9f9",
                                color: "#000",
                                fontWeight: 600,
                                borderBottom: "2px solid #0056b3",
                                padding: "12px",
                              },
                            }}
                            mantineTableBodyCellProps={{
                              style: {
                                padding: "12px",
                                borderBottom: "1px solid #eee",
                                transition: "background-color 0.3s",
                              },
                            }}
                          />
                        : <Center><Text>No Data Avialable</Text></Center> 
                      }
                      </Box>
                    </SimpleGrid>
                  </Card.Section>
                </Card>
              </Tabs.Panel>

              <Tabs.Panel value="invoices">
                <Card shadow="0">
                  <Card.Section withBorder inheritPadding py="xs">
                    <Group>
                      <Text size="sm" mt={10} fw={600}>
                        Invoices
                      </Text>
                    </Group>
                    <SimpleGrid cols={1} mt={10} mb={20}>
                      <Table mt={20}>
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Invoice Number</Table.Th>
                            <Table.Th>Created Date</Table.Th>
                            <Table.Th>Action</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody></Table.Tbody>
                      </Table>
                    </SimpleGrid>
                  </Card.Section>
                </Card>
              </Tabs.Panel>
            </Tabs>
          </Card.Section>
        </Card>
        <DeleteAccount />
      </Container>
    </React.Fragment>
  );
}

export { ProfilePage };
