import { useEffect, useState } from 'react';
import { Card, Title, Loader, Container } from '@mantine/core';
import { LineChart } from '@mantine/charts';

const AnalyticsChart = (props:any) => {
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    if(props.chartData) {
        const formattedChartData = formatChartData();
        setChartData(formattedChartData);  
    }
  }, [props.chartData]);

  const formatChartData = () => {
    return props.chartData.map((item:any) => ({
      date: formatDateGeneric(item.date),
      OptIns: item.optIns,
      OptOuts: item.optOuts,
    }));
  };

  const formatDateGeneric = (dateString: string): string => {
    if (dateString.includes("-W")) {
      // Handle ISO week format (e.g., "2025-W03")
      const [year, week] = dateString.split("-W").map(Number);
      if (!year || !week) {
        return dateString; // Return as-is if the format is invalid
      }
  
      // Calculate the first day of the year
      const jan4 = new Date(year, 0, 4); // January 4th is always in the first ISO week
      const firstWeekDay = jan4.getDay(); // Day of the week (0 = Sunday, 6 = Saturday)
  
      // Calculate the first Thursday of the year (start of the first ISO week)
      const firstThursday = new Date(jan4);
      firstThursday.setDate(jan4.getDate() - ((firstWeekDay + 6) % 7) + 3);
  
      // Calculate the start date of the specified week
      const startDate = new Date(firstThursday);
      startDate.setDate(firstThursday.getDate() + (week - 1) * 7 - 3);
  
      // Calculate the end date of the specified week
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
  
      // Format the start and end dates
      const options: Intl.DateTimeFormatOptions = { month: "short", day: "numeric" };
      const formattedStart = startDate.toLocaleDateString("en-US", options);
      const formattedEnd = endDate.toLocaleDateString("en-US", options);
  
      return `${formattedStart} - ${formattedEnd}`;
    } else if (dateString.length === 7 && dateString.includes("-")) {
      // Handle monthly format (e.g., "2025-01")
      const [year, month] = dateString.split("-").map(Number);
      if (!year || !month) {
        return dateString; // Return as-is if the format is invalid
      }
  
      // Format month and year
      const date = new Date(year, month - 1); // Create date object for the given month
      const options: Intl.DateTimeFormatOptions = { month: "short" };
      const formattedMonth = date.toLocaleDateString("en-US", options);
  
      return `${formattedMonth}`;
    } else {
      // Handle standard date format (e.g., "2025-01-18")
      const options: Intl.DateTimeFormatOptions = { month: "short", day: "numeric" };
      const formattedDate = new Date(dateString).toLocaleDateString("en-US", options);
      return formattedDate;
    }
  };
  
  return (
    <Card withBorder shadow="sm">
          <Title order={4}>Consent Analytics</Title>
          {chartData && chartData.length > 0 ? (
             <LineChart
                h={300}
                data={chartData}
                dataKey="date"
                withLegend
                series={[
                    { name: 'OptIns', color: 'blue' },
                    { name: 'OptOuts', color: 'orange' },
                ]}
                curveType="natural"
            />
          ) : (
            <p>No data available for the selected period.</p>
          )}
        </Card>
  );
};

export default AnalyticsChart;
