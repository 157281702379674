import {
  Grid,
  Paper,
  Text,
  Container,
  Space,
  Title,
  Group,
  Button,
  Card,
  Checkbox,
  Tabs,
  Accordion,
  Switch,
  SimpleGrid,
} from "@mantine/core";
import {
  IconCircleCheckFilled,
} from "@tabler/icons-react";
import React, { useContext, useEffect, useState } from "react";
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import MapComponent from './MapComponent';
import { ConsentService } from "../../services/consentService";
import { ConsentOptionsConfig } from "../../Models/consentOptionsConfig";
import { showNotification } from '@mantine/notifications';
import { ConfigContext } from "../../Context/configContext";

const consentExpiryOptions : ConsentOptionsConfig[] =  [{checked: true,label: "6 Months"},{checked: false,label: "12 Months"},{checked: false,label: "18 Months"},{checked: false,label: "24 Months"}];

function ConfigurationPage(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(props?.currentConsentData?.[0]?.domainId);
  const [selectedConsentExpiry, setConsentExpiry] = useState<any | null>(null);
  const [consentData, setConsentData] = useState<any | null>(null);
  const [locations, setLocations] = useState<any[]>(['Asia-Pacific']);
  const [consentLog, setConsentLog] = useState(false);
  const [subdomainConsentSharing, setSubdomainConsentSharing] = useState(false);
  const render = (status: Status) => (<h1>{status}</h1>);

  const consentService  = new ConsentService(config!);

  const handleConsentSettings = () => {
      consentData['consentLog'] = consentLog;
      consentData['subdomainConsentSharing'] = subdomainConsentSharing;
      updateConsentData();
  };

  const handleConsentExpiryData = () => {
     consentData['consentExpire'] = selectedConsentExpiry;
     updateConsentData();
  };

  const handleLocations = () => {
      consentData['locations'] = locations;
       updateConsentData();
  };

  const updateConsentData = () => {
       const response = consentService.updateConsent(domainId, consentData);
       response.then((res) => {
           if(res) {
               setDomainId(res?.id);
               showNotification({ color: "green", message: "Update successfully!" });
           }
       });
  };

  useEffect(() => {
    if(domainId) {
       const response = consentService.getConsentById(domainId);
       response.then((res) => {
          if(res?.data){
            setConsentData(res?.data);
            setConsentExpiry(res?.data?.consentExpire);
            setLocations(res?.data?.locations);
            setConsentLog(res?.data?.consentLog);
            setSubdomainConsentSharing(res?.data?.subdomainConsentSharing);
          }
       });
   }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <>
        <Container fluid>
          <Space h="lg" />
          <Title order={2}>Configuration</Title>
          <Space h="lg" />
          {domainId && (
              <Card withBorder shadow="sm" radius="md">
                <Card.Section withBorder inheritPadding mt="sm" pb="md">
                  <Tabs defaultValue="geoLocation">
                    <Tabs.List>
                      <Tabs.Tab value="geoLocation">Geographical Location</Tabs.Tab>
                      <Tabs.Tab value="consentExpiry">
                        Consent Expiry Settings
                      </Tabs.Tab>
                      <Tabs.Tab value="consentSettings">Consent Settings</Tabs.Tab>
                      <Tabs.Tab value="payment">Payment</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="geoLocation">
                      <Card.Section withBorder inheritPadding py="xs" mb="sm">
                      <Title order={4} mt="xl">Geographical Location</Title>
                      <Space h="sm" />
                      <Text size="xs" mb="xl">
                        Please select the Geographical Location for which you want
                        to achieve data privacy compliance.
                      </Text>
                      <Grid>
                        <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
                          <Accordion transitionDuration={0} multiple value={locations} onChange={setLocations}>
                            <Accordion.Item value="Africa" style={{ border: "none"}}>
                              <Accordion.Control
                                style={{ border: "1px solid #eaeaea", borderRadius:'7px'}}
                              >
                                <Group justify="left">
                                  <Checkbox checked={locations?.includes('Africa')} size="xs" label="Africa" />
                                </Group>
                              </Accordion.Control>
                              <Accordion.Panel>
                                <Space h="md" />
                                <Checkbox checked={locations?.includes('Africa')}
                                  size="xs"
                                  label="Africa ePrivacy Directive"
                                  description="A Complement to the Africa GDPR Directive concerns the  processing of person data and the protection of privacy in the electronic communications sector, providing specific requirements regarding metadata, cookies, e-marketing and more."
                                />
                                <Space h="md" />
                                <Checkbox checked={locations?.includes('Africa')}
                                  size="xs"
                                  label="Africa ePrivacy Directive"
                                  description="A Complement to the Africa GDPR Directive concerns the  processing of person data and the protection of privacy in the electronic communications sector, providing specific requirements regarding metadata, cookies, e-marketing and more."
                                />
                                <Space h="md" />
                                <Checkbox checked={locations?.includes('Africa')}
                                  size="xs"
                                  label="Africa ePrivacy Directive"
                                  description="A Complement to the Africa GDPR Directive concerns the  processing of person data and the protection of privacy in the electronic communications sector, providing specific requirements regarding metadata, cookies, e-marketing and more."
                                />
                              </Accordion.Panel>
                            </Accordion.Item>
                            <Accordion.Item value="Asia-Pacific" style={{ marginTop: "10px", border: "none", borderRadius:'5px'}}>
                              <Accordion.Control
                                style={{ border: "1px solid #eaeaea" , borderRadius:'7px'}}
                              >
                                <Group justify="left">
                                  <Checkbox checked={locations?.includes('Asia-Pacific')} size="xs" label="Asia Pacific" />
                                </Group>
                              </Accordion.Control>
                              <Accordion.Panel>
                                <Space h="md" />
                                <Checkbox checked={locations?.includes('Asia-Pacific')}
                                  size="xs"
                                  label="Asia Pacific ePrivacy Directive"
                                  description="A Complement to the Asia Pacific GDPR Directive concerns the  processing of person data and the protection of privacy in the electronic communications sector, providing specific requirements regarding metadata, cookies, e-marketing and more."
                                />
                                <Space h="md" />
                                <Checkbox checked={locations?.includes('Asia-Pacific')}
                                  size="xs"
                                  label="Asia Pacific ePrivacy Directive"
                                  description="A Complement to the Asia Pacific GDPR Directive concerns the  processing of person data and the protection of privacy in the electronic communications sector, providing specific requirements regarding metadata, cookies, e-marketing and more."
                                />
                                <Space h="md" />
                                <Checkbox checked={locations?.includes('Asia-Pacific')}
                                  size="xs"
                                  label="Asia Pacific ePrivacy Directive"
                                  description="A Complement to the Asia Pacific GDPR Directive concerns the  processing of person data and the protection of privacy in the electronic communications sector, providing specific requirements regarding metadata, cookies, e-marketing and more."
                                />
                              </Accordion.Panel>
                            </Accordion.Item>

                            <Accordion.Item value="Europe" style={{ marginTop: "10px" , border: "none", borderRadius:'5px'}}>
                              <Accordion.Control
                                style={{ border: "1px solid #eaeaea", borderRadius:'7px' }}
                              >
                                <Group justify="left">
                                  <Checkbox checked={locations?.includes('Europe')} size="xs" label="Europe" />
                                </Group>
                              </Accordion.Control>
                              <Accordion.Panel>
                                <Space h="md" />
                                <Checkbox
                                   checked={locations?.includes('Europe')}
                                  size="xs"
                                  label="EU ePrivacy Directive"
                                  description="A Complement to the EU GDPR Directive concerns the  processing of person data and the protection of privacy in the electronic communications sector, providing specific requirements regarding metadata, cookies, e-marketing and more."
                                />
                                <Space h="md" />
                                <Checkbox
                                   checked={locations?.includes('Europe')}
                                  size="xs"
                                  label="EU ePrivacy Directive"
                                  description="A Complement to the EU GDPR Directive concerns the  processing of person data and the protection of privacy in the electronic communications sector, providing specific requirements regarding metadata, cookies, e-marketing and more."
                                />
                                <Space h="md" />
                                <Checkbox
                                   checked={locations?.includes('Europe')}
                                  size="xs"
                                  label="EU ePrivacy Directive"
                                  description="A Complement to the EU GDPR Directive concerns the  processing of person data and the protection of privacy in the electronic communications sector, providing specific requirements regarding metadata, cookies, e-marketing and more."
                                />
                              </Accordion.Panel>
                            </Accordion.Item>
                          </Accordion>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 7 }}>
                           {locations.length > 0 && (
                            <Wrapper apiKey={"AIzaSyC8noELHoE04HIQmqVXrt56XPQlVZ4stZE"} render={render}>
                              <MapComponent mapLocationData={locations?.[0]} />
                            </Wrapper>
                           )}
                        </Grid.Col>
                      </Grid>
                      <Space h="sm" />
                      </Card.Section>
                      <Card.Section inheritPadding py="xs">
                        <Button variant="filled" size="xs" onClick={() => {handleLocations()}}>
                          Save Changes
                        </Button>
                      </Card.Section>
                    </Tabs.Panel>

                    <Tabs.Panel value="consentExpiry">
                      <Card shadow="0">
                        <Card.Section withBorder inheritPadding py="xs">
                          <Space h="lg" />
                          <Group justify="space-between">
                            <Title order={4}>Geographical Location</Title>
                          </Group>
                          <Space h="sm" />
                          <Text size="xs">
                            Please choose the cookie consent duration with 12 months
                            as default.
                          </Text>
                          <Space h="lg" />
                          <Group>
                            <SimpleGrid cols={4}>
                                {consentExpiryOptions.map((consentExpiry, index) => (
                                 <Paper shadow="0px" pt="xs" pb="xs" pl="xl" pr="xl" withBorder style={{ position: "relative",cursor:"pointer" }} onClick={(event) => {setConsentExpiry(consentExpiry.label)}}>
                                     <Text size="xs">{consentExpiry.label}</Text>
                                     {selectedConsentExpiry == consentExpiry.label && (
                                        <IconCircleCheckFilled  className="primary"
                                            size="1rem"
                                            stroke={3}
                                            style={{ left: "-8px", top: "-7px", position: "absolute" }}
                                        />
                                     )}
                                  </Paper>
                                ))}
                            </SimpleGrid>
                          </Group>
                          <Space h="lg" />
                          <Space h="lg" />
                        </Card.Section>
                        <Card.Section withBorder inheritPadding py="xs">
                          <Space h="sm" />
                          <Button variant="filled" size="xs" onClick={(event) => {handleConsentExpiryData()}}>
                            Save Changes
                          </Button>
                        </Card.Section>
                      </Card>
                    </Tabs.Panel>

                    <Tabs.Panel value="consentSettings">
                      <Card shadow="0">
                        <Card.Section withBorder inheritPadding py="xs">
                          <Space h="lg" />
                          <Group justify="space-between">
                            <Title order={4}>Consent Settings</Title>
                          </Group>
                          <Space h="sm" />
                          <Group>
                            <Text size="xs">Export all consents and close</Text>
                            <Button variant="filled" size="xs" color="indigo">
                              Export
                            </Button>
                            <Button variant="outline" size="xs" color="indigo">
                              Close
                            </Button>
                          </Group>
                          <Space h="lg" />
                          <Group>
                            <Text size="xs">Consent Log</Text>
                            <Space w="xl" />
                            <Space w="xl" />
                            <Switch size="xs" checked={consentLog} onChange={(event) => setConsentLog(event.currentTarget.checked)} />
                          </Group>
                          <Space h="lg" />
                          <Group>
                            <Text size="xs">Subdomain Consent sharing</Text> {subdomainConsentSharing}
                            <Space w="0" />
                            <Switch size="xs" checked={subdomainConsentSharing} onChange={(event) => setSubdomainConsentSharing(event.currentTarget.checked)} />
                          </Group>
                          <Space h="lg" />
                        </Card.Section>
                        <Card.Section withBorder inheritPadding py="xs">
                          <Space h="sm" />
                          <Button variant="filled" size="xs"  onClick={(event) => {handleConsentSettings()}}>
                            Save Changes
                          </Button>
                        </Card.Section>
                      </Card>
                    </Tabs.Panel>
                  </Tabs>
                </Card.Section>
              </Card>
          )}
          {!domainId && (
           <div>
             <Card shadow="sm" p="xl"  mb="xl">
                 <Text ta="center" size="xs" c="red" fw="700">
                      No Configuration Found ! Please configure or select Consent first
                 </Text>
             </Card>
           </div>
         )}
        </Container>
      </>
    </React.Fragment>
  );
}

export { ConfigurationPage };
