import {
  Text,
  Container,
  Space,
  Card,
  Group,
  Alert,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { Step5 } from "./consent/Step5";
import { ConsentService } from "../../services/consentService";
import { showNotification } from '@mantine/notifications';
import { ConfigContext } from "../../Context/configContext";
import { useTitle } from "../hooks/useTitle";
import { IconInfoCircle } from "@tabler/icons-react";
import { themesConfig } from "@/utils/utils";

const tempObj = {language:'en',domainId:'',domainUrl:'',geoLocations:[],consentExpire:'',styles:'', locations:[]};
function ConsentBannerPage(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(props?.currentConsentData?.domainId || localStorage.getItem('activeDomainId'));
  const [domainName, setDomainName] = useState<any | null>(localStorage.getItem('activeDomain'));
  const [consentConfigRequest, setConsentConfigRequest] = useState<any>(tempObj);
  const [configuredTheme, setConfiguredTheme] = useState<any | null>(null);
  
  const consentService = new ConsentService(config!);

  useTitle("Manage Consent Banner");

  useEffect(() => {
    if (props.currentConsentData) {
      setDomainId(props.currentConsentData.domainId);
    }
  }, [props.currentConsentData]);

  useEffect(() => {
      if(domainId) {
        getConsentById(domainId);
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);

  const getConsentById = async (id:any) => {
    const response = consentService.getConsentById(id);
    response.then((res) => {
       if(res?.data){
           setConsentConfigRequest(res?.data);

           if(res?.data?.theme) {
              try {
                setConfiguredTheme(res?.data);
              } catch (error) {
                setConfiguredTheme(JSON.parse(res?.data));
              }
           } else {
               let domainObj = res?.data;
               domainObj.theme = themesConfig[0];
               setConfiguredTheme(domainObj);
           }

           localStorage.setItem("activeDomain", res?.data?.domainUrl);
       }
    });
  }

  const handleConsentData = (fieldValue:any) => {
        const payload : any = {};

        payload.consentExpire = consentConfigRequest.consentExpire;
        payload.geoLocations = ['Asia-Pacific'];
        payload.domainUrl = consentConfigRequest.domainUrl;
        payload.language = consentConfigRequest.language;
        payload.subdomainConsentSharing = true;
        payload.consentLog = true;
        payload.styles = fieldValue;

        const response = consentService.updateConsent(domainId, payload);
        response.then((res) => {
           if(res) {
                setDomainId(res?.id);
                setDomainName(res?.domainUrl);
                showNotification({ color: "green", message: "Update successfully!" });
           }
        })
  };

  return (
    <React.Fragment>
      <>
        <Container fluid>
           <Space h="lg" />
            <div>
              <Card shadow="sm" p="xl"  mb="sm">
                 <Text size="xs">
                      Set up the user interface of your consent banner, cookie declaration and privacy trigger.
                  </Text>
              </Card>
              <Alert variant="light" color="orange" mb={20}>
                  <Group gap={3}>
                    <IconInfoCircle />
                    <Text fs="italic" size="sm">
                        <strong>Legal disclaimer: </strong> 
                        Please note that we recommend checking your setup with your Data Protection Officer.
                    </Text>
                  </Group>  
              </Alert>
              {domainId && configuredTheme && (
                <Step5 consentData={handleConsentData} consentConfigData={configuredTheme} domainId={domainId} domainName={domainName}/>
              )}
            </div>
        </Container>
      </>
    </React.Fragment>
  );
}

export { ConsentBannerPage };
