import {
  Grid,
  Text,
  Container,
  Group,
  Card,
  Button,
  Title,
  Image,
  Stack,
  em,
  Center,
  LoadingOverlay,
  Space
} from "@mantine/core";
import {
  IconChartPie,
  IconClockRecord,
  IconDoorEnter,
  IconDoorExit,
} from "@tabler/icons-react";
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useContext } from "react";
import { ConfigContext } from "../../Context/configContext";
import { Wizard } from "./consentV2/Wizard";
import dashBoardBg from "../../images/dashboard-bg.png";
import classes from './dashboad.module.css';
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { useMediaQuery } from '@mantine/hooks';
import { ScanService } from "../../services/scanService";
import { ConsentService } from "../../services/consentService";
import { showNotification } from "@mantine/notifications";
import apiClient from "../../services/apiClient";
import { getAuthorizationHeader } from "../../utils/getAuthorizationHeader";

interface AnalyticsResponse {
  totalOptIns: number;
  totalOptOuts: number;
  averageTimeToConsent: number;
  mostAcceptedCookie: string;
  leastAcceptedCookie: string;
  chartData: Array<any>; // You can define a more specific type if needed
}

function DashboardPage(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(props?.currentConsentData?.domainId || localStorage.getItem('activeDomainId'));
  const [isShowWizard, setIsShowWizard] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const [necessaryCount, setNecessaryCount] = useState(0);
  const [preferencesCount, setPreferencesCount] = useState(0);
  const [statisticsCount, setStatisticsCount] = useState(0);
  const [marketingCount, setMarketingCount] = useState(0);
  const [unClassifiedCount, setUnClassifiedCount] = useState(0);
  const [analyticsData, setAnalyticsData] = useState<any>(null);
  const [isDataAvailable, setIsDataAvailable] = useState(false); // Initialize to false
  const [loading, setLoading] = useState(false);
  const [isUserHaveDomain, setIsUserHaveDomain] = useState(false);  

  const consentService = new ConsentService(config!);

  useEffect(() => {
    if (props.currentConsentData) {
      setDomainId(props.currentConsentData.domainId);
    }
  }, [props.currentConsentData]);

  useEffect(() => {
    getDomainScanDataById(domainId);
    fetchAnalyticsData();
    getDomainData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);

  const formatAverageTimeToConsent = (timeInSeconds:number) => {
    const roundedTime = Math.round(timeInSeconds);
    
    if (roundedTime < 60) {
      return `${roundedTime} sec`;
    } else {
      const minutes = Math.floor(roundedTime / 60);
      const seconds = roundedTime % 60;
      return `${minutes} min ${seconds} sec`;
    }
  };

  const getDomainData = async () => {
    setLoading(true);
    try {
      const response = await consentService.getDomains();
      console.log("API Response:", response); // Log the response

      if (response && response.data) {
        setIsUserHaveDomain(response.data.length > 0);
      }
    } catch (error) {
      console.error("Error fetching domains:", error);
      showNotification({ color: "red", message: "Failed to fetch domains." });
    } finally {
      setLoading(false);
    }
  };

  const data = [
    {
      title: "Total Opt-ins",
      subTitle: '',
      description: analyticsData?.totalOptIns.toString(),
      icon: <IconDoorEnter className="primary" size={70} stroke={1}/>,
      color: "var(--mantine-primary-color-filled)",
      bgColor: "#FFE5B4",
      span:4
    },
    {
      title: "Total Opt-outs",
      subTitle: '',
      description: analyticsData?.totalOptOuts.toString(),
      icon: <IconDoorExit size={70} color="blue" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
      span:4
    },
    {
      title: "Avg Time to Consent",
      subTitle: '',
      description: analyticsData?.averageTimeToConsent ? formatAverageTimeToConsent(analyticsData.averageTimeToConsent): "N/A",      
      icon: <IconClockRecord size={70} color="orange" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
      span:4
    },
    {
      title: "Most Consented Cookie",
      subTitle: 'cookie that users most likely give consent to: ',
      description: analyticsData?.mostAcceptedCookie.toString(),
      icon: <IconChartPie size={70} color="blue" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
      span:6
    },
    {
      title: "Least Consented Cookie",
      subTitle: 'cookie that users least likely give consent to: ',
      description: analyticsData?.leastAcceptedCookie.toString(),
      icon: <IconChartPie size={70} color="orange" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
      span:6
    },
  ];

  const fetchAnalyticsData = async () => {
    if (domainId) {
      setLoading(true);
      try {
        const response = await apiClient.get<AnalyticsResponse>(
          `${config!.apiBaseUrl}/domains/${domainId}/analytics`,
          {
            params: {
              startDate: '',
              endDate: '',
            },
            headers: getAuthorizationHeader()
          }
        );
        setAnalyticsData(response.data);
        setIsDataAvailable(response.data.chartData.length > 0);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
        setIsDataAvailable(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const getDomainScanDataById = async(domainId:string) => {
    if(domainId) {
      const scanService = new ScanService(config!);
      try {
        setLoading(true);
        const res = await scanService.getScanReport(domainId);
        if(res){
          setNecessaryCount(res?.latestScanResult?.necessaryCookies || 0);
          setPreferencesCount(res?.latestScanResult?.preferenceCookies || 0);
          setStatisticsCount(res?.latestScanResult?.statisticsCookies || 0);
          setMarketingCount(res?.latestScanResult?.marketingCookies || 0);
          setUnClassifiedCount(res?.latestScanResult?.unclassifiedCookies || 0);
        } 
      } catch (error) {
        console.error("Error fetching domain scan data:", error);
        showNotification({ color: "red", message: "Failed to fetch domain scan data." });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGetStartedClick = () => {
    setIsShowWizard(true);
  };

  const handleDomainSelection = (domain:string, name:string) => {
    setDomainId(domain);
    setIsUserHaveDomain(domain !== null);
    getDomainScanDataById(domain);
  };

  return (
    <React.Fragment>
      {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
    <>
     {!props?.user?.newUser && isUserHaveDomain ?
      <Container size="lg" py="lg">
        <Grid gutter="md" style={{ alignItems: "stretch" }}>
          {/* News Updates */}
          <Grid.Col span={{ base: 12, md: 6, lg: 7 }}>
            <Card
              shadow="sm"
              padding="lg"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "300px",
                height: "100%",
              }}
            >
              <Title order={4}>News Updates</Title>
              <Space my="xs" />
              <Stack gap={5}>
                <Text fw={500}>GDPR Compliance Changes</Text>
                <Text size="sm">
                  New updates on the GDPR compliance affecting data handling practices.
                </Text>
                <Text fw={500} mt={10}>CCPA Amendments</Text>
                <Text size="sm">
                  Recent amendments in CCPA that businesses need to be aware of.
                </Text>
                <Text fw={500} mt={10}>Global Data Privacy Laws</Text>
                <Text size="sm">
                  An overview of data privacy laws being enacted globally.
                </Text>
              </Stack>
            </Card>
          </Grid.Col>
      
          {/* Recent Scan Report */}
          <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
            <Card
              shadow="sm"
              padding="lg"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "300px",
                height: "100%",
              }}
            >
              <Title order={4}>Recent Scan Report</Title>
              <Space my="xs" />
              <Stack gap={8}>
                <Text>Necessary: {necessaryCount}</Text>
                <Text>Preference: {preferencesCount}</Text>
                <Text>Statistics: {statisticsCount}</Text>
                <Text>Marketing: {marketingCount}</Text>
                <Text>Unclassified: {unClassifiedCount}</Text>
              </Stack>
              <Group justify="right" mt="auto">
                <Button variant="subtle" component={Link} to={`/privacy-audits`}>
                  View detailed report →
                </Button>
              </Group>
            </Card>
          </Grid.Col>
        </Grid>
      
        <Grid gutter="md" style={{ alignItems: "stretch" }}>
          {/* Consent Analytics */}
          <Grid.Col span={{ base: 12, md: 6, lg: 7 }}>
            <Card
              shadow="sm"
              padding="lg"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "300px",
                height: "100%",
              }}
            >
              <Title order={4}>Consent Analytics</Title>
              <Space my="xs" />
              <Grid>
                {isDataAvailable ? (
                  data.map((stat, index) => (
                    <Grid.Col key={index} span={stat.span}>
                      <Card shadow="xs" padding="sm" bg="#f2f4fa">
                        <Text size="sm" fw={700}>
                          {stat.title}
                        </Text>
                        <Text size="sm">{stat.description}</Text>
                      </Card>
                    </Grid.Col>
                  ))
                ) : (
                  <Grid.Col span={12}>
                    <Center h={135}>
                      <Text>No Data Available</Text>
                    </Center>
                  </Grid.Col>
                )}
              </Grid>
              <Group justify="right" mt="auto">
                <Button variant="subtle" component={Link} to={`/analytics`}>
                  View details →
                </Button>
              </Group>
            </Card>
          </Grid.Col>
      
          {/* Consent Banner Configuration */}
          <Grid.Col span={{ base: 12, md: 6, lg: 5 }}>
            <Card
              shadow="sm"
              padding="lg"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "300px",
                height: "100%",
              }}
            >
              <Title order={4}>Consent Banner Configuration</Title>
              <Space my="xs" />
              <Text size="sm">
                Configure your consent banner to ensure legal compliance, build user
                trust, and provide clear, granular data preferences.
              </Text>
              <Text mt="sm" size="sm">
                By configuring your consent banner effectively, you safeguard user
                rights and ensure a compliant, user-centric platform experience.
              </Text>
              <Group justify="right" mt="auto">
                <Button variant="subtle" component={Link} to={`/consentBanners`}>
                  Start/Update Configuration →
                </Button>
              </Group>
            </Card>
          </Grid.Col>
        </Grid>
      </Container>        
      :
          <>
          <Container style={{maxWidth: '100%', overflow:'hidden'}} pl={0} pr={0}>
            <div className={classes.wrapper}>
                <Grid align="center" gutter="lg" mt={isMobile?"xl":"0"}>
                  <Grid.Col span={{ base: 12, sm:12, md: 8, lg:8 }}>
                    <Stack pl={30}>
                      <Title order={1} style={{ fontSize: '2rem' }}>Welcome to Cookiex!</Title>
                      <Text size="md">
                        Your trusted partner in managing consent with ease and compliance.
                      </Text>
                      <Button mt={20} size="md" variant="filled" color="blue" style={{ width: 'fit-content' }} rightSection={<IconArrowNarrowRight />} onClick={handleGetStartedClick}>
                        Get Started
                      </Button>
                    </Stack>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Image src={dashBoardBg} alt="Illustration" className={classes.image}/>
                  </Grid.Col>
                </Grid>
              </div>
              <div style={{ backgroundColor: '#fff', padding:'2rem'}}>
                <Title order={2} style={{ marginBottom: '1rem' }}>
                  Key Features
                </Title>
                <Grid gutter="md" mt={30}>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Card bg="#f5f5f5" padding="lg" radius="md" h={150}>
                      <Title order={4}>Automated Cookie Scanning</Title>
                      <Text size="sm" mt={10}>
                        Regularly scan your website to identify and categorize cookies and tracking technologies.
                      </Text>
                    </Card>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Card bg="#f5f5f5" padding="lg" radius="md" h={150}>
                      <Title order={4}>Customizable Consent Banners</Title>
                      <Text size="sm" mt={10}>
                        Tailor consent notices to align with your brand and inform users about data collection practices.
                      </Text>
                    </Card>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Card bg="#f5f5f5" padding="lg" radius="md" h={150}>
                      <Title order={4}>Consent Log Management</Title>
                      <Text size="sm" mt={10}>
                        Securely maintain detailed records of user consent decisions to ensure transparency.
                      </Text>
                    </Card>
                  </Grid.Col>
                </Grid>
              </div>
            </Container>
            {isShowWizard && (
              <Wizard user={props} />
            )}
          </>
        }  
      </>
    </React.Fragment>
  );
}

export { DashboardPage };
